import * as configurations from "../config/configuration"
const oktaAuthConfig = {

    issuer: configurations.default.ISSUER_OKTA,
    clientId: configurations.default.CLIENT_OKTA,
    redirectUri: configurations.default.REDIRECT_UI_OKTA,
    scopes:configurations.default.OKTA_SCOPES
  };
  
  const oktaSignInConfig = {
    baseUrl: configurations.default.BASE_URL_OKTA,
    clientId: configurations.default.CLIENT_OKTA,
    redirectUri: configurations.default.REDIRECT_UI_OKTA,
    scopes:configurations.default.OKTA_SCOPES,
    authParams: {

    }
  };
  
  export { oktaAuthConfig, oktaSignInConfig };