const host = window.location.hostname

const configuration_staging = {
    REDIRECT_UI_OKTA: window.location.origin + '/login/callback',
    ISSUER_OKTA: "https://secure.ite.pepsico.com/oauth2/default",
    CLIENT_OKTA: "0oa116w9q0nDP6hTu0h8",
    BASE_URL_OKTA: 'https://secure.ite.pepsico.com',
    WEB_URL: "https://dashboard.wspuat.com/api/v1",
    PROJECT_TEMPLATE: "https://dashboard.wspuat.com/api/v1/project/template",
    OKTA_SCOPES: ['email','openid','profile','wsp-scope'],
    pkce:true
}
const configuration_local = {
    REDIRECT_UI_OKTA: window.location.origin + '/login/callback',
    WEB_URL: "http://localhost:3001/api/v1",
    PROJECT_TEMPLATE: "http://localhost:3001/api/v1/project/template",
    ISSUER_OKTA: "https://secure.ite.pepsico.com/oauth2/default",
    CLIENT_OKTA: "0oa116w9q0nDP6hTu0h8",
    BASE_URL_OKTA: 'https://secure.ite.pepsico.com',
    OKTA_SCOPES: ['email','openid','profile','wsp-scope'],
    pkce:true
}
const configuration_live = {
    REDIRECT_UI_OKTA: window.location.origin + '/login/callback',
    WEB_URL: "https://websitecontrolcenter.pepsico.com/api/v1",
    PROJECT_TEMPLATE: "https://websitecontrolcenter.pepsico.com/api/v1/project/template",
    ISSUER_OKTA: "https://secure.pepsico.com/oauth2/aus1ljxpv3z9G8NGl0h8",
    CLIENT_OKTA: "0oa1n3xpen9l5J2YE0h8",
    BASE_URL_OKTA: 'https://secure.pepsico.com',
    OKTA_SCOPES: ['email','openid','profile','wsp-scope','address','phone', 'offline_access','pepappwspdashboardroles'],
    pkce:true
}
var configurations = configuration_staging

if (host === 'websitecontrolcenter.pepsico.com'){
    configurations = configuration_live
}

if (host == 'localhost') {
    configurations = configuration_local
}

export default configurations
