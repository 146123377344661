import { Types } from '../constants/domain-types';

const initialState = {
    domainsList: [],
    fields: []
};

export default function domainReducer(state = initialState, action: any) {
    switch (action.type) {
        case Types.DOMAINS_FETCHED_SUCCESSFULLY:
            return { ...state, domainsList: action.payload.body };
        case Types.DOMAIN_ITEMS:
            return { ...state, fields: action.payload };
        default:
            return state;
    }
}