import * as configurations from "../config/configuration"
export const Config = {
    web_url : configurations.default.WEB_URL,
    project_template :configurations.default.PROJECT_TEMPLATE,
    technology:'/technology/',
    app_owner:"/app-owner/",
    agency :"/agency/",
    name_server:"/name-server/",
    project:"/project/",
    registrar:"/registrar/"  ,
    subDomain:"/sub-domain/",   
    asg:"/asg/",
    alb:"/alb/",
    billingcategory:"/billing-category/",
    billingcontact:"/billing-contact/",
    gtmga :"/gtm-ga/",
    globalaccelerator:"/global-accelerator-ip/",
    targetgroup:"/target-group/",
    authenticate:"/user/authenticate/",
    dashboard:"/report/",
    user:"/user/users",
    domain: "/domain/",
}