import { Types } from '../constants/user-types';

const initialState = {
    loginSuccess: "",
    status : null,
    loginData:{}
};
export default function technologyReducer(state = initialState, action: any) {
    switch (action.type) {
        
        case Types.LOGIN_SUCCESSFULLY:
            return { ...state, status: action.payload.status ,loginData: action.payload.body,  loginSuccess:action.payload.message}
        default:
            return state;
    }
}