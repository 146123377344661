export const Types ={
    PROJECT_ADDED_SUCCESSFULLY: 'PROJECT_ADDED_SUCCESSFULLY',
    PROJECT_UPDATED_SUCCESSFULLY:'PROJECT_UPDATED_SUCCESSFULLY',
    PROJECT_DELETED_SUCCESSFULLY:'PROJECT_DELETED_SUCCESSFULLY',
    PROJECT_FETCHED_SUCCESSFULLY:'PROJECT_FETCHED_SUCCESSFULLY',
    PROJECTS_FETCHED_SUCCESSFULLY:"PROJECTS_FETCHED_SUCCESSFULLY",
    PROJECT_COUNT_SUCCESSFULLY:"PROJECT_COUNT_SUCCESSFULLY",
    PROJECT_ITEMS:"PROJECT_ITEMS",
    PROJECT_IMPORT:"PROJECT_IMPORT",
    CLEAR_ALL:"CLEAR_ALL",
    PROJECT_EXPORT:"PROJECT_EXPORT",
    PROJECT_EXISTS:"PROJECT_EXISTS"
}