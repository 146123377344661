import { Types } from '../constants/dashboard-types';

const initialState = {
    statusGraph: [],
    sectorGraph: [],
    platformGraph: [],
    countryGraph: [],
    sitetypeGraph: [],
    filteredSectors: [],
    filteredCountries: [],
    filteredProjects: [],
    piiGraph:[],
    projectReport:{},
    projectReportExport:[]

};
export default function globalAcceleratorReducer(state = initialState, action: any) {
    switch (action.type) {

        case Types.PROJECT_STATUS_FETCHED_SUCCESSFULLY:
            return { ...state, statusGraph: action.payload.body }
        case Types.PROJECT_SECTOR_FETCHED_SUCCESSFULLY:
            return { ...state, sectorGraph: action.payload.body }
        case Types.PROJECT_PLATFORM_FETCHED_SUCCESSFULLY:
            return { ...state, platformGraph: action.payload.body }
        case Types.PROJECT_COUNTRY_FETCHED_SUCCESSFULLY:
            return { ...state, countryGraph: action.payload.body }
        case Types.PROJECT_SITE_TYPE_FETCHED_SUCCESSFULLY:
            return { ...state, sitetypeGraph: action.payload.body }
        case Types.FILTERED_SECTORS:
            return { ...state, filteredSectors: action.payload.body }
        case Types.FILTERED_COUNTRIES:
            return { ...state, filteredCountries: action.payload.body }
        case Types.FFILTERED_PROJECTS:
            return { ...state, filteredProjects: action.payload.body }
        case Types.PROJECT_PII_FETCHED_SUCCESSFULLY:
            return { ...state, piiGraph: action.payload.body }
            case Types.EXPORT_PROJECT_LIST_FETCHED_SUCCESSFULLY:
                return { ...state, projectReportExport: action.payload.body }
            case Types.PROJECT_LIST_FETCHED_SUCCESSFULLY:
            return { ...state, projectReport: action.payload.body }
        default:
            return state;
    }
}

