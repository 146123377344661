import { Types } from '../constants/website-types';

const initialState = {
    projectaddedSuccessfully: "",
    projectdeletedSuccessfully: "",
    projectpdatedSuccessfully: "",
    projectlist: {},
    project: {},
    status: null,
    projectCount: {},
    import_message: '',
    errorList: [],
    exportList: [],
    website_exists: false
};
export default function projectReducer(state = initialState, action: any) {
    
    switch (action.type) {

        case Types.PROJECTS_FETCHED_SUCCESSFULLY:
            return { ...state, projectlist: action.payload.body }
        case Types.PROJECT_ADDED_SUCCESSFULLY:
            return { ...state, status: action.payload.status, projectaddedSuccessfully: action.payload.message }
        case Types.PROJECT_UPDATED_SUCCESSFULLY:
            return { ...state, status: action.payload.status, projectupdatedSuccessfully: action.payload.message }
        case Types.PROJECT_DELETED_SUCCESSFULLY:
            return { ...state, status: action.payload.status, projectdeletedSuccessfully: action.payload.message }
        case Types.PROJECT_FETCHED_SUCCESSFULLY:
            return { ...state, project: action.payload }
        case Types.PROJECT_COUNT_SUCCESSFULLY:
            return { ...state, projectCount: action.payload.body }
        case Types.PROJECT_IMPORT:
            return { ...state, import_message: action.payload.data.message, errorList: action.payload.data.body }
        case Types.PROJECT_EXPORT:
            return { ...state, import_message: action.payload.data.message, exportList: action.payload.data.body }
        case Types.PROJECT_ITEMS:
            return { ...state, fields: action.payload }
        case Types.CLEAR_ALL:
            return { ...state, import_message: '', errorList: [] }
        case Types.PROJECT_EXISTS:
            return { ...state, website_exists: action.payload.data && action.payload.data.message === "Websites Fetched Successfully"? true : false }
        default:
            return state;
    }
}