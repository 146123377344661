import { Types } from '../constants/user-admin-types';

const initialState = {

    userList:{},
    updateUser:{},
    userUpdatedMessage:""
};
export default function globalAcceleratorReducer(state = initialState, action: any) {
    switch (action.type) {

        case Types.GET_ALL_USERS:
            return { ...state, userList: action.payload.body  && action.payload.body.result ? action.payload.body.result : [] }
            case Types.UPDATE_USER:
                console.log(action.payload.message)
                return { ...state,updateUser : action.payload , userUpdatedMessage: action.payload.message} 
        default:
            return state;
    }
}

