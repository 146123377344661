export const Types ={
    PROJECT_STATUS_FETCHED_SUCCESSFULLY:'PROJECT_STATUS_FETCHED_SUCCESSFULLY',
    PROJECT_SECTOR_FETCHED_SUCCESSFULLY:"PROJECT_SECTOR_FETCHED_SUCCESSFULLY",
    PROJECT_PLATFORM_FETCHED_SUCCESSFULLY:"PROJECT_PLATFORM_FETCHED_SUCCESSFULLY",
    PROJECT_COUNTRY_FETCHED_SUCCESSFULLY:"PROJECT_COUNTRY_FETCHED_SUCCESSFULLY",
    PROJECT_SITE_TYPE_FETCHED_SUCCESSFULLY:"PROJECT_SITE_TYPE_FETCHED_SUCCESSFULLY",
    FILTERED_SECTORS:'FILTERED_SECTORS',
    FILTERED_COUNTRIES:'FILTERED_COUNTRIES',
    FFILTERED_PROJECTS:'FFILTERED_PROJECTS',
    PROJECT_PII_FETCHED_SUCCESSFULLY:"PROJECT_PII_FETCHED_SUCCESSFULLY",
    PROJECT_LIST_FETCHED_SUCCESSFULLY:"PROJECT_LIST_FETCHED_SUCCESSFULLY",
    EXPORT_PROJECT_LIST_FETCHED_SUCCESSFULLY:"EXPORT_PROJECT_LIST_FETCHED_SUCCESSFULLY"
}