import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store  from './redux/store/store';
import App from './App';
import config from './config';
import reportWebVitals from './reportWebVitals';

import './assets/scss/style.scss';
import './fonts/index.css'
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
reportWebVitals();
