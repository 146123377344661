import { Types } from '../constants/agency-types';

const initialState = {
    addedSuccessfully: "",
    deletedAgencyMessage: "",
    updatedSuccessfully: "",
    agencylist: [],
    agency: {},
    status: null,
    agencyCount: {},
    agency_exists: false

};
export default function agencyReducer(state = initialState, action: any) {
    switch (action.type) {
        case Types.AGENCIES_FETCHED_SUCCESSFULLY:
            return { ...state, agencylist: action.payload.body }
        case Types.AGENCY_ADDED_SUCCESSFULLY:
            return { ...state, addedSuccessfully: action.payload.message }
        case Types.AGENCY_UPDATED_SUCCESSFULLY:
            return { ...state, updatedSuccessfully: action.payload.message }
        case Types.AGENCY_DELETED_SUCCESSFULLY:
            return { ...state, deletedAgencyMessage: action.payload.message }
        case Types.AGENCY_FETCHED_SUCCESSFULLY:
            return { ...state, agency: action.payload.body[0] }
        case Types.AGENCY_COUNT_SUCCESSFULLY:
            return { ...state, agencyCount: action.payload.body }
            case Types.CHECK_AGENCY_EXISTS:
                return { ...state, agency_exists: action.payload && action.payload.message === "Agencies Fetched Successfully"? true : false }
            default:
            return state;
    }
}