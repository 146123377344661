import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
// routing  
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { oktaAuthConfig } from './okta/config';

import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "./redux/actions/user-actions"
import theme from './themes';
import * as config_role from './config/role';
import NavigationScroll from './layout/NavigationScroll';
import Loadable from './ui-component/Loadable';
import '../src/scss/colors.scss'
import 'antd/dist/antd.css';
// import './font/Cocogoose-Pro-Standard-18473/Cocogoose-Pro-Standard-18473/Fonts/Otf/Cocogoose-Inline.otf';


const role = config_role.config_role ? config_role.config_role : ''
const oktaAuth = new OktaAuth(oktaAuthConfig);
const AdminRoutes = Loadable(React.lazy(() => import('./routes/AdminRoutes')));

//-----------------------|| APP ||-----------------------//


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
interface stateInterface {
  loader: boolean
}
interface propsInterface {
  loginData: any,
  authUser: Function
}
class App extends React.Component<propsInterface, stateInterface> {
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false
    }
    this.restoreOriginalUri = this.restoreOriginalUri.bind(this)
  }
  customAuthHandler() {

  };
  async restoreOriginalUri(_oktaAuth: any, originalUri: any) {

    try {
      const tokens = localStorage.getItem('okta-token-storage')

      if (tokens) {
        let token = JSON.parse(tokens)
        sessionStorage.setItem('test1',token.idToken.claims.pepappwspdashboardroles)
        sessionStorage.setItem('testRole', token.idToken.claims.pepappwspdashboardroles && token.idToken.claims.pepappwspdashboardroles.length ? token.idToken.claims.pepappwspdashboardroles[0] : '');
        let params = {
          token: token.accessToken.accessToken,
          name: token.idToken.claims.name,
          email: token.idToken.claims.email,
          username: token.idToken.claims.preferred_username,
          role: token.idToken.claims.pepappwspdashboardroles && token.idToken.claims.pepappwspdashboardroles.length ? token.idToken.claims.pepappwspdashboardroles[0] : '',
          // group: {hostingPlatform:"WSP"}
        }
        this.setState({ loader: true })
        await this.props.authUser(params)
        if (this.props.loginData && this.props.loginData.email) {
          this.setState({ loader: false })
          window.location.replace('/dashboard');
        }
      }
    } catch (error) {
      // this.setState({loader: false})
    }

  };
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme({})}>
          <CssBaseline />
          <NavigationScroll>
            <Security
              oktaAuth={oktaAuth}
              onAuthRequired={this.customAuthHandler}
              restoreOriginalUri={this.restoreOriginalUri}
            >
              <Router >
                <React.Suspense fallback={loading}>
                  <Switch>
                    <AdminRoutes role={role} />
                  </Switch>
                </React.Suspense>
              </Router>

            </Security>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

};

function mapStateToProps(state: any) {
  return {
    ...state.user,
    ...state.customization
  };
}
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ ...userActions }, dispatch)
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App) as any
);