const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/admin',
    defaultPath: '/website',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
