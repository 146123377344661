import { combineReducers } from 'redux';
import agencyReducer from './agency-reducers';
import websiteReducer from './website-reducers';
import userReducer from './user-reducers';
import dashboardReducer from './dashboard-reducers';
import customizationReducer from './customization-reducer';
import userAdminReducer from './user-admin-reducers';
import domainReducer from './domain-reducers';

const reducers = combineReducers({
    agency: agencyReducer,
    project: websiteReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    customization: customizationReducer,
    userAdmin: userAdminReducer,
    domain: domainReducer
});

export default reducers;