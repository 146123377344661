import API from '../apis/user-api';
import { Types } from "../constants/user-types";
//  Authenticate User
export function authUser(params: any) {
    return async function (dispatch: any, getState: any) {
        try {
            let user = await API.authenticateUser(params);
            if (user && user.data && user.data.body) {
                saveToken(user.data.body.token);
                saveRole(user.data.body.role)
                saveUser(user.data.body._id)
                saveUserName(user.data.body.name)
                saveGroup(user.data.body.group)
            }
            dispatch({ type: Types.LOGIN_SUCCESSFULLY, payload: user.data });
        } catch (err) {
        }
    };
    function saveToken(token: any) {
        localStorage.setItem('token_pep', token)
    }
    function saveRole(role: any) {
        localStorage.setItem('userrole', btoa(role))
    }
    function saveUser(role: any) {
        localStorage.setItem('user', btoa(role))
    }
    function saveUserName(role: any) {
        localStorage.setItem('name', btoa(role))
    }
    function saveGroup(group: any) {
        localStorage.setItem('group', btoa(group))
    }
}