import axios from 'axios'
import { Config } from '../../config/apis'
const web_url = Config.web_url + Config.authenticate

const authenticateUser = async (token:any) => {
    return axios.post(web_url, token)
};

export default {
    authenticateUser
}